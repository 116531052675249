<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('Schools')"
      :breadcrumb="[
              {
                text: $t('Schools'),
                active: true,
              },
            ]"
    ></custom-breadcrumb>
    <!--SIDEBAR MODAL DE ADD-NEW -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :countries-options="countriesOptions"
      :plan-options="planOptions"
      @refetch-data="getData"
    />

<b-card no-body>

      <!-- FILTROS -->
       <filters
        ref="filter"
        context="schools"
        :plan-options="planOptions"
        :countries-options="countriesOptions"
        :status-options="statusOptions"
        @set-data="setData"
        @set-loading-data="setLoadingData"
      /> 

      <!-- TABLE  -->

      <paginated-table
        :data="schools"
        ability="Schools"
        link='school'
        :canDownload="false"
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        @recover="recoverModel"
        :loading_data="loading_data"

        :buttonsOptions="buttonsOptions"
        @create="isAddNewSidebarActive = true"
        @deleteSelection="deleteSelection"
      ></paginated-table>

    </b-card>
  </div>
</template>

<script>
import { recoverSchool, deleteMasiveSchool, getSchools, deleteSchool } from '@/api/routes'
import { avatarText } from '@core/utils/filter'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
} from 'bootstrap-vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'

import vSelect from 'vue-select'
import AddNew from './AddNew.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
    name: "LicencesList",
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BCardHeader,
        BCardTitle, 
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSkeletonTable,

        vSelect,

        PaginatedTable,
        Filters,
        AddNew,
        CustomBreadcrumb
    },
    setup(){
        return {
            avatarText
        }
    },
    data(){
        return {
            perPage: 10,
            currentPage: 1,
            countriesOptions: [],
            planOptions: [],
            langsOptions: [],
            statusOptions: [
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ],
            role_selected: null,
            status_selected: null,
            schools: [],
            records_length: null, 
            loading_data: false,
            fetch_message: '',

            isAddNewSidebarActive: false,
            selected: [],
        }
    },
    computed: {
      buttonsOptions(){
        let opt = []
        if(this.$can('create', 'Schools')){
          opt.push(
            {
              action: "create",
              variant: "success",
              icon: "PlusIcon",
              name: this.$t('forms.add_model', {model: this.$t('models.school')})
            }
          )
          if(this.selected.length > 0){
            opt.push({
                  action: "deleteSelection",
                  icon: "Trash2Icon",
                  name: this.$t('table.deactivate_selection', {model: this.$t('models.schools')}),
                  variant: "danger",
                })
          }
        }
        return opt
      },
        dataMeta(){
            let total = this.records_length
            let ini = total >= 1 ? 1 : 0;
            let res = (total % this.perPage)
            let total_pages = Math.floor(total / this.perPage) +1
            //let n = Math.abs(res); // Change to positive
            //var decimal = n - Math.floor(res)
            let localItemsCount = this.perPage;
            // console.log(res, this.currentPage, total_pages )
            if(this.currentPage == total_pages){
                localItemsCount = this.perPage + res
            }
            return {
                from: this.perPage * (this.currentPage - 1) + (ini ? 1 : 0),
                to: this.currentPage == total_pages ? total : this.perPage * (this.currentPage - 1) + (ini ? localItemsCount:0),
                of: total,
            }
        }
    },
    methods: {
        recoverModel(id){
          this.$bvModal
            .msgBoxConfirm('Are you sure you want to recover this school?', {
              title: 'Recover School',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if(value){
                let data = {
                  'school_id': id
                }
                this.$http.post(recoverSchool, data).then( response =>{
                  // console.log(response)
                  if(response.data.status == 200){
                    this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

                  } else {
                    // console.log(response.data, "NO SE PUEDE RECUPERAR");
                    this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                  }

                }).catch(error => {
                  // console.log("Err -> ", error)
                })
              }
            })
        },
        manageSelectedRows(selected_rows){
          // console.log(selected_rows, "selected_rows")
          this.selected = selected_rows
        },
        deleteSelection(){
          this.$bvModal
            .msgBoxConfirm(this.$t('table.delete_selection_confirmation', {num:this.selected.length}), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if(value){
                let sel = this.selected.map(item => item.id)
                let data = {
                  'records_ids':   sel
                }
                // console.log(data)
                this.$http.post(deleteMasiveSchool, data).then( response =>{
                  // console.log(response)
                  if(response.data.status == 200){
                    this.$refs.filter.search();

                this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                  } else {
                    // console.log(response.data, "NO SE PUEDE ELIMINAR");
                    this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                  }

                }).catch(error => {
                  // console.log("Err -> ", error)
                })
              }
            })
        
        },
        async getData(){
          this.loading_data = true
          // console.log("loading")
          await this.$http.post(getSchools, {}).then( response =>{
            // console.log(response)
            let countries = response.data.countries;
            let plans = response.data.plans;
            this.schools = response.data.schools;
            this.fetch_message = ''
            this.loading_data = false
            this.records_length = response.data.schools.length

            //PLANS
            let newPlansOptions = []
            plans.forEach(x => {
              let can_select = true;
              if(x.canEdit==0){
                can_select = false;
              }
              newPlansOptions.push(
                  { 'value': x.id, 'label': x.description, 'can_select': can_select }
              ) 
            });
            this.planOptions = newPlansOptions;

            //Countries
            let newCountriesOptions = []
            countries.forEach(x => {
            let capName = x.name.charAt(0).toUpperCase() + x.name.slice(1);
            newCountriesOptions.push(
                { 'value': x.code, 'label': capName }
            ) 
            });
            this.countriesOptions = newCountriesOptions;
          }).catch(error => {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          })
        },
        setLoadingData(state){
            this.loading_data = state
        },
        setData(data){
            if(data.records_length > 40000){
                this.schools = []
                this.fetch_message = this.$t('table.too_many_records')
                this.loading_data = false
                this.records_length = 0
            } else {
                this.fetch_message = ''
                this.schools = data.schools;
                this.loading_data = false
                this.records_length = data.records_length
            }
        },
        deleteModel(id){
          this.$bvModal
            .msgBoxConfirm(this.$t('schools.sure'), {
              title: this.$t('schools.delete'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if(value){
                let data = {
                  'school_id': id
                }
                this.$http.post(deleteSchool, data).then( response =>{
                  // console.log(response)
                  if(response.data.status == 200){
                    this.$refs.filter.search();

                    this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

                  } else {
                    // console.log(response.data, "NO SE PUEDE ELIMINAR");
                    this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                  }

                }).catch(error => {
                  // console.log("Err -> ", error)
                })
              }
            })
        },
        makeToast(variant = null, tit ='',msg = '') {
          this.$bvToast.toast(msg, {
            title: tit,
            variant,
            solid: false,
          })
        },
    },
    mounted(){
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>